export const requestDetailsContent = {
  EN: {
    renderRequestDetailsContent: {
      requestHeading: "Request Details",
      video: "Video",
      mediaItemText: "Your browser does not support the video tag",
      thumbnail: "Thumbnail",
      title_: "Title",
      description_: "Description",
      audience_: "Audience",
      kids: "kids",
      adults: "adults",
      visibility_: "Visibility",
      category: "Category",
      creator: "Creator",
      editor: "Editor",
      requestedOn: "Requested on",
      requestStatus_: "Request Status",
      respondedOn: "Responded on",

      uploadStatus: "Upload Status",
      uploaded: "uploaded",
      pending: "pending",
      approved: "approved",
      approve: "Approve",
      reject: "Reject",
      rejected: "rejected",
      upload: "Upload",
      resendRequest: "Resend Request",
      deleteRequest: "Delete Request",
    },
    renderFetchingErrorContent: {
      notFoundError: "Sorry, We couldn't find request details",
      otherError: "Sorry, We are unable to get request details",
      retry: "Retry",
    },
    renderUploadResponseContent: {
      inProgressMessage: "Please Wait! we are sending your request",

      goBack: "go back",
    },
  },
  HI: {
    renderRequestDetailsContent: {
      requestHeading: "अनुरोध विवरण",
      video: "वीडियो",
      mediaItemText: "आपका ब्राउज़र में वीडियो टैग समर्थित नहीं है",
      thumbnail: "थंबनेल",
      title_: "शीर्षक",
      description_: "विवरण",
      audience_: "श्रोता",
      kids: "बच्चे",
      adults: "वयस्कों",
      visibility_: "दृश्यता",
      category: "वर्ग",
      creator: "निर्माता",
      editor: "संपादक",
      requestedOn: "पर अनुरोध किया गया",
      requestStatus_: "अनुरोध की स्थिति",
      respondedOn: "पर प्रतिक्रिया व्यक्त की",
      uploadStatus: "अपलोड स्थिति",
      uploaded: "अपलोड किए गए",
      pending: "लंबित",
      approved: "अनुमत",
      rejected: "अस्वीकार कर दिया",
      approve: "मंज़ूरी देना",
      reject: "अस्वीकार करना",
      upload: "डालना",
      resendRequest: "अनुरोध पुनः भेजें",
      deleteRequest: "अनुरोध हटा दें",
    },
    renderFetchingErrorContent: {
      notFoundError: "क्षमा करें, हमें अनुरोध विवरण नहीं मिल सका",
      otherError: "क्षमा करें, हम अनुरोध विवरण प्राप्त करने में असमर्थ हैं",
      retry: "पुन: प्रयास करें",
    },
    renderUploadResponseContent: {
      inProgressMessage: "कृपया प्रतीक्षा करें! हम आपका अनुरोध भेज रहे हैं",
      goBack: "वापस जाओ",
    },
  },
  TE: {
    renderRequestDetailsContent: {
      requestHeading: "అభ్యర్థన వివరాలు",
      video: "వీడియో",
      mediaItemText: "మీ బ్రౌజర్ వీడియో ట్యాగ్‌కు మద్దతు ఇవ్వదు",
      thumbnail: "సూక్ష్మచిత్రం",
      title_: "శీర్షిక",
      description_: "వివరణ",
      audience_: "ప్రేక్షకులు",
      kids: "పిల్లలు",
      adults: "పెద్దలు",
      visibility_: "దృశ్యత",
      category: "వర్గం",
      creator: "సృష్టికర్త",
      editor: "ఎడిటర్",
      requestedOn: "న అభ్యర్థించారు",
      requestStatus_: "అభ్యర్థన స్థితి",
      respondedOn: "పై స్పందించారు",
      uploadStatus: "అప్‌లోడ్ స్థితి",
      uploaded: "అప్‌లోడ్ చేయబడింది",
      pending: "పెండింగ్‌లో ఉంది",
      approve: "ఆమోదించడానికి",
      reject: "తిరస్కరించు",
      approved: "ఆమోదించబడింది",
      rejected: "తిరస్కరించారు",
      upload: "అప్లోడ్",
      resendRequest: "అభ్యర్థనను మళ్లీ పంపండి",
      deleteRequest: "అభ్యర్థనను తొలగించండి",
    },
    renderFetchingErrorContent: {
      notFoundError: "క్షమించండి, మేము అభ్యర్థన వివరాలను కనుగొనలేకపోయాము",
      otherError: "క్షమించండి, మేము అభ్యర్థన వివరాలను పొందలేకపోతున్నాము",
      retry: "మళ్లీ ప్రయత్నించండి",
    },
    renderUploadResponseContent: {
      inProgressMessage: "దయచేసి వేచి ఉండండి! మేము మీ అభ్యర్థనను పంపుతున్నాము",
      goBack: "వెనక్కి వెళ్ళు",
    },
  },
  ZH: {
    renderRequestDetailsContent: {
      requestHeading: "請求詳情",
      video: "影片",
      mediaItemText: "您的瀏覽器不支援video標籤",
      thumbnail: "縮圖",
      title_: "標題",
      description_: "描述",
      audience_: "觀眾",
      kids: "孩子們",
      adults: "成年人",
      visibility_: "能見度",
      category: "類別",
      creator: "創作者",
      editor: "編輯",
      requestedOn: "請求於",
      requestStatus_: "請求狀態",
      respondedOn: "回覆於",
      approve: "核准",
      reject: "拒絕",
      uploadStatus: "上傳狀態",
      uploaded: "已上傳",
      pending: "待辦的",
      approved: "得到正式認可的",
      rejected: "拒絕",
      upload: "上傳",
      resendRequest: "重新發送請求",
      deleteRequest: "刪除請求",
    },
    renderFetchingErrorContent: {
      notFoundError: "抱歉，我們找不到請求詳細信息",
      otherError: "抱歉，我們無法獲取請求詳細信息",
      retry: "重試",
    },
    renderUploadResponseContent: {
      inProgressMessage: "請稍等！我們正在發送您的請求",

      goBack: "回去",
    },
  },
  ES: {
    renderRequestDetailsContent: {
      requestHeading: "Pedir detalles",
      video: "Video",
      mediaItemText: "Su navegador no soporta la etiqueta de vídeo",
      thumbnail: "Miniatura",
      title_: "Título",
      description_: "Descripción",
      audience_: "Audiencia",
      kids: "niños",
      adults: "adultos",
      visibility_: "Visibilidad",
      category: "Categoría",
      creator: "Creador",
      editor: "Editor",
      requestedOn: "Solicitado el",
      requestStatus_: "Estado de la solicitud",
      respondedOn: "Respondió el",
      approve: "Aprobar",
      reject: "Rechazar",
      uploadStatus: "Estado de carga",
      uploaded: "subido",
      pending: "pendiente",
      approved: "aprobado",
      rejected: "rechazado",
      upload: "Subir",
      resendRequest: "Reenviar solicitud",
      deleteRequest: "Borrar petición",
    },
    renderFetchingErrorContent: {
      notFoundError:
        "Lo sentimos, no pudimos encontrar los detalles de la solicitud.",
      otherError:
        "Lo sentimos, no podemos obtener los detalles de la solicitud.",
      retry: "Rever",
    },
    renderUploadResponseContent: {
      inProgressMessage: "¡Espere por favor! estamos enviando su solicitud",

      goBack: "regresa",
    },
  },
  FR: {
    renderRequestDetailsContent: {
      requestHeading: "Détails de la demande",
      video: "Vidéo",
      mediaItemText: "Votre navigateur ne prend pas en charge la balise vidéo",
      thumbnail: "Vignette",
      title_: "Titre",
      description_: "Description",
      audience_: "Public",
      kids: "enfants",
      adults: "adultes",
      visibility_: "Visibilité",
      category: "Catégorie",
      creator: "Créateur",
      editor: "Éditeur",
      requestedOn: "Demandé le",
      requestStatus_: "Statut de la demande",
      respondedOn: "A répondu le",
      approve: "Approuver",
      reject: "Rejeter",
      uploadStatus: "Statut de téléchargement",
      uploaded: "téléchargé",
      pending: "en attente",
      approved: "approuvé",
      rejected: "rejeté",
      upload: "Télécharger",
      resendRequest: "Renvoyer la demande",
      deleteRequest: "Supprimer la demande",
    },
    renderFetchingErrorContent: {
      notFoundError:
        "Désolé, nous n'avons pas trouvé les détails de la demande",
      otherError:
        "Désolé, nous ne parvenons pas à obtenir les détails de la demande",
      retry: "Recommencez",
    },
    renderUploadResponseContent: {
      inProgressMessage:
        "S'il vous plaît, attendez! nous envoyons votre demande",

      goBack: "retourner",
    },
  },
  AR: {
    renderRequestDetailsContent: {
      requestHeading: "طلب تفاصيل",
      video: "فيديو",
      mediaItemText: "متصفحك الحالي لا يدعم تشغيل الفيديو",
      thumbnail: "ظفري",
      title_: "عنوان",
      description_: "وصف",
      audience_: "جمهور",
      kids: "أطفال",
      adults: "الكبار",
      visibility_: "الرؤية",
      category: "فئة",
      creator: "المنشئ",
      editor: "محرر",
      requestedOn: "طلب على",
      requestStatus_: "حالة الطلب",
      respondedOn: "تم الرد على",
      approve: "يعتمد",
      reject: "يرفض",
      uploadStatus: "حالة التحميل",
      uploaded: "تم الرفع",
      pending: "قيد الانتظار",
      approved: "موافقة",
      rejected: "مرفوض",
      upload: "رفع",
      resendRequest: "إعادة إرسال الطلب",
      deleteRequest: "حذف طلب",
    },
    renderFetchingErrorContent: {
      notFoundError: "عذرًا، لم نتمكن من العثور على تفاصيل الطلب",
      otherError: "عذرًا، لا يمكننا الحصول على تفاصيل الطلب",
      retry: "أعد المحاولة",
    },
    renderUploadResponseContent: {
      inProgressMessage: "انتظر من فضلك! نحن نرسل طلبك",

      goBack: "عُد",
    },
  },
  BN: {
    renderRequestDetailsContent: {
      requestHeading: "বিস্তারিত অনুরোধ",
      video: "ভিডিও",
      mediaItemText: "আপনার ব্রাউজার ভিডিও ট্যাগ সমর্থন করে না",
      thumbnail: "থাম্বনেইল",
      title_: "শিরোনাম",
      description_: "বর্ণনা",
      audience_: "শ্রোতা",
      kids: "বাচ্চাদের",
      adults: "প্রাপ্তবয়স্কদের",
      visibility_: "দৃশ্যমানতা",
      category: "শ্রেণী",
      creator: "সৃষ্টিকর্তা",
      editor: "সম্পাদক",
      requestedOn: "অনুরোধ করা হয়েছে",
      requestStatus_: "অনুরোধের স্থিতি",
      respondedOn: "উপর উত্তর",
      approve: "অনুমোদন করুন",
      reject: "প্রত্যাখ্যান করুন",
      uploadStatus: "আপলোড স্থিতি",
      uploaded: "আপলোড",
      pending: "বিচারাধীন",
      approved: "অনুমোদিত",
      rejected: "প্রত্যাখ্যাত",
      upload: "আপলোড করুন",
      resendRequest: "অনুরোধ আবার পাঠান",
      deleteRequest: "অনুরোধ মুছুন",
    },
    renderFetchingErrorContent: {
      notFoundError: "দুঃখিত, আমরা অনুরোধের বিবরণ খুঁজে পাইনি",
      otherError: "দুঃখিত, আমরা অনুরোধের বিবরণ পেতে অক্ষম",
      retry: "পুনরায় চেষ্টা করা",
    },
    renderUploadResponseContent: {
      inProgressMessage:
        "অনুগ্রহপূর্বক অপেক্ষা করুন! আমরা আপনার অনুরোধ পাঠাচ্ছি",

      goBack: "ফিরে যাও",
    },
  },
  RU: {
    renderRequestDetailsContent: {
      requestHeading: "Детали запроса",
      video: "видео",
      mediaItemText: "Ваш браузер не поддерживает видео тег",
      thumbnail: "Миниатюра",
      title_: "Заголовок",
      description_: "Описание",
      audience_: "Аудитория",
      kids: "Дети",
      adults: "Взрослые",
      visibility_: "Видимость",
      category: "Категория",
      creator: "Создатель",
      editor: "редактор",
      requestedOn: "Запрошено",
      requestStatus_: "Статус запроса",
      respondedOn: "Ответил на",

      uploadStatus: "Статус загрузки",
      uploaded: "загружено",
      pending: "в ожидании",
      approve: "Утвердить",
      reject: "Отклонять",
      approved: "одобренный",
      rejected: "отклоненный",
      upload: "Загрузить",
      resendRequest: "Повторно отправить запрос",
      deleteRequest: "Удалить запрос",
    },
    renderFetchingErrorContent: {
      notFoundError: "К сожалению, нам не удалось найти детали запроса.",
      otherError:
        "К сожалению, мы не можем получить подробную информацию о запросе.",
      retry: "Повторить попытку",
    },
    renderUploadResponseContent: {
      inProgressMessage: "Пожалуйста, подождите! мы отправляем ваш запрос",

      goBack: "возвращаться",
    },
  },
  PT: {
    renderRequestDetailsContent: {
      requestHeading: "Pedir detalhes",
      video: "Vídeo",
      mediaItemText: "Seu navegador não suporta a tag de vídeo",
      thumbnail: "Miniatura",
      title_: "Título",
      description_: "Descrição",
      audience_: "Público",
      kids: "crianças",
      adults: "adultos",
      visibility_: "Visibilidade",
      category: "Categoria",
      creator: "Criador",
      editor: "Editor",
      requestedOn: "Solicitado em",
      requestStatus_: "Status da solicitação",
      respondedOn: "Respondeu em",

      uploadStatus: "Carregar status",
      uploaded: "carregado",
      pending: "pendente",
      approve: "Aprovar",
      reject: "Rejeitar",
      approved: "aprovado",
      rejected: "rejeitado",
      upload: "Carregar",
      resendRequest: "Reenviar solicitação",
      deleteRequest: "Apagar solicitação",
    },
    renderFetchingErrorContent: {
      notFoundError:
        "Desculpe, não foi possível encontrar os detalhes da solicitação",
      otherError: "Desculpe, não foi possível obter detalhes da solicitação",
      retry: "Tentar novamente",
    },
    renderUploadResponseContent: {
      inProgressMessage: "Por favor, aguarde! estamos enviando sua solicitação",

      goBack: "volte",
    },
  },
  UR: {
    renderRequestDetailsContent: {
      requestHeading: "تفصیلات کی درخواست کریں۔",
      video: "ویڈیو",
      mediaItemText: "آپ کا براؤزر ویڈیو ٹیگ کو سپورٹ نہیں کرتا ہے۔",
      thumbnail: "تھمب نیل",
      title_: "عنوان",
      description_: "تفصیل",
      audience_: "سامعین",
      kids: "بچے",
      adults: "بالغوں",
      visibility_: "مرئیت",
      category: "قسم",
      creator: "خالق",
      editor: "ایڈیٹر",
      requestedOn: "پر درخواست کی۔",
      requestStatus_: "درخواست کی حیثیت",
      respondedOn: "پر جواب دیا۔",
      approve: "منظور کرو",
      reject: "رد کرنا",
      uploadStatus: "اپ لوڈ کی حیثیت",
      uploaded: "اپ لوڈ",
      pending: "زیر التواء",
      approved: "منظورشدہ",
      rejected: "مسترد",
      upload: "اپ لوڈ کریں۔",
      resendRequest: "درخواست دوبارہ بھیجیں۔",
      deleteRequest: "درخواست کو حذف کریں۔",
    },
    renderFetchingErrorContent: {
      notFoundError: "معذرت، ہمیں درخواست کی تفصیلات نہیں مل سکیں",
      otherError: "معذرت، ہم درخواست کی تفصیلات حاصل کرنے سے قاصر ہیں۔",
      retry: "دوبارہ کوشش کریں۔",
    },
    renderUploadResponseContent: {
      inProgressMessage:
        "برائے مہربانی انتظار کریں! ہم آپ کی درخواست بھیج رہے ہیں۔",

      goBack: "واپس جاو",
    },
  },
};
