export const accessibilitySectionContent = {
  EN: {
    increaseText: "Increase Text",
    decreaseText: "Decrease Text",
    removeGrayscale: "Remove Grayscale",
    grayScale: "Grayscale",
    removeUnderlines: "Remove Underlines",
    showUnderlines: "Show Underlines",
    resetSettings_: "Reset Settings",
  },
  HI: {
    increaseText: "पाठ बढ़ाएँ",
    decreaseText: "पाठ कम करें",
    removeGrayscale: "ग्रेस्केल हटाएँ",
    grayScale: "स्केल",
    removeUnderlines: "अंडरलाइन हटाएं",
    showUnderlines: "अंडरलाइन दिखाएँ",
    resetSettings_: "सेटिंग्स फिर से करिए",
  },
  TE: {
    increaseText: "వచనాన్ని పెంచండి",
    decreaseText: "వచనాన్ని తగ్గించండి",
    removeGrayscale: "గ్రేస్కేల్ తొలగించండి",
    grayScale: "గ్రేస్కేల్",
    removeUnderlines: "అండర్‌లైన్‌లను తీసివేయండి",
    showUnderlines: "అండర్‌లైన్‌లను చూపించు",
    resetSettings_: "రీసెట్ సెట్టింగులు",
  },
  ZH: {
    increaseText: "增加文字",
    decreaseText: "減少文字",
    removeGrayscale: "刪除灰階",
    grayScale: "灰階",
    removeUnderlines: "刪除底線",
    showUnderlines: "顯示底線",
    resetSettings_: "重新設定",
  },
  ES: {
    increaseText: "Aumentar texto",
    decreaseText: "Disminuir texto",
    removeGrayscale: "Quitar escala de grises",
    grayScale: "Escala de grises",
    removeUnderlines: "Eliminar subrayados",
    showUnderlines: "Mostrar subrayados",
    resetSettings_: "Reiniciar ajustes",
  },
  FR: {
    increaseText: "Augmenter le texte",
    decreaseText: "Diminuer le texte",
    removeGrayscale: "Supprimer les niveaux de gris",
    grayScale: "Niveaux de gris",
    removeUnderlines: "Supprimer les soulignements",
    showUnderlines: "Afficher les soulignements",
    resetSettings_: "Réinitialiser les options",
  },
  AR: {
    increaseText: "زيادة النص",
    decreaseText: "تقليل النص",
    removeGrayscale: "إزالة التدرج الرمادي",
    grayScale: "تدرج الرمادي",
    removeUnderlines: "إزالة التسطير",
    showUnderlines: "إظهار التسطير",
    resetSettings_: "اعادة الضبط",
  },
  BN: {
    increaseText: "টেক্সট বাড়ান",
    decreaseText: "পাঠ্য হ্রাস করুন",
    removeGrayscale: "গ্রেস্কেল সরান",
    grayScale: "গ্রেস্কেল",
    removeUnderlines: "আন্ডারলাইন সরান",
    showUnderlines: "আন্ডারলাইন দেখান",
    resetSettings_: "রিসেট সেটিংস",
  },
  RU: {
    increaseText: "Увеличить текст",
    decreaseText: "Уменьшить текст",
    removeGrayscale: "Удалить оттенки серого",
    grayScale: "Оттенки серого",
    removeUnderlines: "Удалить подчеркивания",
    showUnderlines: "Показать подчеркивания",
    resetSettings_: "Сбросить настройки",
  },
  PT: {
    increaseText: "Aumentar texto",
    decreaseText: "Diminuir Texto",
    removeGrayscale: "Remover escala de cinza",
    grayScale: "Tons de cinza",
    removeUnderlines: "Remover sublinhados",
    showUnderlines: "Mostrar sublinhados",
    resetSettings_: "Redefinir as configurações",
  },
  UR: {
    increaseText: "متن میں اضافہ کریں۔",
    decreaseText: "متن کم کریں۔",
    removeGrayscale: "گرے اسکیل کو ہٹا دیں۔",
    grayScale: "گرے اسکیل",
    removeUnderlines: "انڈر لائنز کو ہٹا دیں۔",
    showUnderlines: "انڈر لائنز دکھائیں۔",
    resetSettings_: "ترتیبات کو دوبارہ ترتیب دیں۔",
  },
};
